import * as React from "react";

import "./ProgressBar.css";

export var ProgressBar = ({ percent, status }) => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    setValue(percent);
  });

  return (
    <div className={"progressComp"}>
      <div className="progress-div">
        <div style={{ width: `${value}px` }} className="progress" />
      </div>
      <h1 className="percent-number">{status}%</h1>
    </div>
  );
};
