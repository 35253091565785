import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
// import { navigate } from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { PayPalButton } from 'react-paypal-button-v2'

import { CartItemsContext } from '../../../context/CartItemsContext'
import api from '../../utils/api'
import axios from 'axios'
import { ProgressBar } from '../ProgressBar'

function encode (data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Confirm = (props) => {
  const { values, handleChange, handleOrderID } = props
  const [formValues, setFormValues] = useState(props.values)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [sending, setSending] = useState(false)
  const [cartItems, setCartItems] = useContext(CartItemsContext)
  const [cartTotal, setCartTotal] = useState('')
  const [waitingTime, setWaitingTime] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [orderTimes, setOrderTimes] = useState([])
  const [opening, setOpening] = useState('')
  const [closing, setClosing] = useState('')
  const [openDays, setOpenDays] = useState()
  const [isPayed, setIsPayed] = useState(false)
  const [paypalAmount, setPaypalAmount] = useState('')
  const [privacyChecked, setPrivacyChecked] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)

  // console.log(cartItems[0].name)

  useEffect(() => {

      api.readOrderTimes().then((ordertimes) => {
        // console.log(takeAwayTimes)
        setOrderTimes(ordertimes)

        console.log(ordertimes)

        const openingtime = orderTimes.map(item => item.data.openingtime)
        setOpening(openingtime)
        // console.log(openingtime[5])

        const closingtime = orderTimes.map(item => item.data.closingtime)
        setClosing(closingtime)
        // console.log(closingtime)

        const waitingtime = orderTimes.map(item => item.data.waitingtime)
        setWaitingTime(waitingtime)

        // const openingDays = takeAwayTimes.map(item => item.data.openingdays).toString()
        const openingDays = orderTimes.filter(item => item.data.checked === true).map(item => item.data.value).toString()
        setOpenDays(openingDays)
        // console.log(openingDays)

      }).catch((e) => {
        console.log('An API error occurred', e)
      })




    // checkOut()

    // api.readAllTables().then((tables) => {
    //   const codes = tables.map(item => item.data.qrcode)
    //
    //   console.log(codes)
    //
    // })
  }, [props])


  useEffect(() => {

    const currentDate = new Date()
    const day = currentDate.getDay()

    // console.log(day)

    // const openingTime = opening[day - 1]
    // const openingTime = opening[day]
    const openingTime = opening[day === 0 ? day : day - 1]
    // console.log(openingTime)
    // const closingTime = closing[day]
    // const closingTime = closing[day - 1]
    const closingTime = closing[day === 0 ? day : day - 1]

    // const takeAwayWaitingTime = waitingTime[day - 1]
    // const takeAwayWaitingTime = waitingTime[day === 0 ? day : day - 1]
    const takeAwayWaitingTime = waitingTime[day === 0 ? 6 : day - 1]
    // console.log(takeAwayWaitingTime)

    // var weekday = new Array(7);
    // weekday[0] = "Sunday";
    // weekday[1] = "Monday";
    // weekday[2] = "Tuesday";
    // weekday[3] = "Wednesday";
    // weekday[4] = "Thursday";
    // weekday[5] = "Friday";
    // weekday[6] = "Saturday";
    // const openingDays = [0,1,2,3,4,5]
    const openingDays = openDays

    console.log(openDays)

    const currentTime = new Date()

    // console.log(currentTime.getDay())
    // console.log(openingTime <= currentTime.toLocaleTimeString() && currentTime.toLocaleTimeString())
    // console.log(openingDays.includes(currentTime.getDay()))

    if (openingTime <= currentTime.toLocaleTimeString() && currentTime.toLocaleTimeString() <= closingTime && openingDays.includes(currentTime.getDay())) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
    console.log(isOpen)

  })


  function checkOpeningTime () {
    // const openingTime = "07:00"
    // const closingTime = "23:00"

    const currentDate = new Date()
    const day = currentDate.getDay()

    // console.log(day)

    // const openingTime = opening[day - 1]
    // const openingTime = opening[day]
    const openingTime = opening[day === 0 ? day : day - 1]
    // console.log(openingTime)
    // const closingTime = closing[day]
    // const closingTime = closing[day - 1]
    const closingTime = closing[day === 0 ? day : day - 1]

    // const takeAwayWaitingTime = waitingTime[day - 1]
    // const takeAwayWaitingTime = waitingTime[day === 0 ? day : day - 1]
    const takeAwayWaitingTime = waitingTime[day === 0 ? 6 : day - 1]
    // console.log(takeAwayWaitingTime)

    // var weekday = new Array(7);
    // weekday[0] = "Sunday";
    // weekday[1] = "Monday";
    // weekday[2] = "Tuesday";
    // weekday[3] = "Wednesday";
    // weekday[4] = "Thursday";
    // weekday[5] = "Friday";
    // weekday[6] = "Saturday";
    // const openingDays = [0,1,2,3,4,5]
    const openingDays = openDays

    // console.log(openingDays)

    const currentTime = new Date()

    // console.log(currentTime.getDay())
    // console.log(openingDays.includes(currentTime.getDay()))

    if (openingTime <= currentTime.toLocaleTimeString() && currentTime.toLocaleTimeString() <= closingTime && openingDays.includes(currentTime.getDay())) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    totalSum()
  }, [cartItems])


  function checkCart () {
    if (typeof window !== 'undefined') {
      //setCartItems(localStorage.getItem("click&collect-cart"))
      if (localStorage.getItem("click&collect-cart") !== null) {
        let localCart = localStorage.getItem("click&collect-cart");
        setCartItems(JSON.parse(localCart))
      } else {
        localStorage.setItem("click&collect-cart", JSON.stringify(cartItems))
      }
    }
  }

  function totalSum () {
    let total = 0

    if (cartItems.length > 0) {
      let filteredPositionSum = cartItems.map((item, i) =>
        parseFloat(item.price.replace(',', '.'))
      )
      // console.log(filteredPositionSum)
      const add = (a, b) => a + b
      total = filteredPositionSum.reduce(add)
      // total = totalPos - parseFloat(invoice.discount).toFixed(2)
      // subtotal = totalPos
      setCartTotal(total.toFixed(2).replace('.', ','))
      setPaypalAmount(total.toFixed(2))
    }
  }

  function handleOrderIdentification (orderno) {
    // setStarttimevalue(starttimevalue)
    handleOrderID(orderno)
  };

  const handleSubmit = e => {
    e.preventDefault()

    setSending(true)

    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name')
        // ...this.state,
      })
    }).catch(error => alert(error))

    const orderNo = Math.ceil(new Date().getTime() * Math.random(1999) / 200000000)

    const date = props.values.date
    const time = timeCast
    const firstname = formValues.formValues.firstname
    const secondname = formValues.formValues.secondname
    const email = formValues.formValues.email
    const tel = formValues.formValues.tel
    const sum = cartTotal
    const fullname = firstname + ' ' + secondname + '!'
    const to = email
    const products = cartItems.map(items => `${ items.name } ${items.price} €`)

    const orderInfo = {
      orderno: orderNo.toString(),
      firstname: firstname,
      secondname: secondname,
      email: email,
      tel: tel,
      products: products,
      checked: false,
      sum: sum,
      payment: 'in place',
      // date: time.toLocaleDateString(),
      // time: time.toLocaleTimeString()
      date: date,
      time: time
    }

    // Make API request to create new order
    api.createOrder(orderInfo).then((response) => {
      console.log(response)

      // if (response.status === 200) {
      setSending(false)
      setSuccess(true)

      // setTimeout(function () {
      //   props.nextStep()
      // }, 1000)

      localStorage.removeItem("click&collect-cart")
      // setCartItems([])

      // setTakeAway(false)
      // setShowThankYouTakeAway(true)
      // }
    }).catch((e) => {
      console.log('An API error occurred', e)

      setError(true)
    })

    const currentDate = new Date()
    const day = currentDate.getDay()
    const orderlist = products
    const takeAwayWaitingTime = waitingTime[day === 0 ? 6 : day - 1]
    const pickuptime = new Date(currentDate.setTime(currentDate.getTime() + (takeAwayWaitingTime * 60000))).toLocaleTimeString('de', { hour: 'numeric', minute: 'numeric' })
    const orderno = orderNo

    axios.post("/.netlify/functions/send-order-confirmation", {
      fullname,
      sum,
      orderlist,
      pickuptime,
      orderno,
      time,
      date,
      tel,
      to
    }).then((response) => {
      // if (response.data.status === 'success'){
      //   alert("Message Sent.");
      //   this.resetForm()
      // }else if(response.data.status === 'fail'){
      //   alert("Message failed to send.")
      // }
      // this.resetForm()
      // console.log('success', response)
      if (response.status === 200) {
        setSending(false)
        setSuccess(true)

        handleOrderIdentification(orderno)

        setTimeout(function () {
          props.nextStep()
        }, 1000)
      }
      return {
        statusCode: 200,
        body: JSON.stringify(response)

      }
    }).catch((error) => {
      // console.log('error', error)
      setError(true)
      return {
        statusCode: 400,
        body: JSON.stringify(error)
      }
    //  console.log('...dont know')
    })
    // props.nextStep()
  }

  function backStep (e) {
    e.preventDefault()
    props.prevStep()
  }

  function renderSuccess () {
    setTimeout(() =>
      setSuccess(false), 15000)
    if (success === true) {
      return (
        <Success>
          Erfolgreich gesendet<FontAwesomeIcon icon={faCheck} />
        </Success>
      )
    }
  }

  function renderSendingTime () {
    if (sending === true) {
      return (
        <Info>
          wird gesendet...
        </Info>
      )
    }
  }

  function renderError () {
    setTimeout(() =>
      setError(false), 15000)
    if (error === true) {
      return (
        <Error>
          Konnte nicht gesendet werden. Bitte veruchen Sie es noch einmal{' '}<FontAwesomeIcon icon={faTimes} />
        </Error>
      )
    }
  }

  const paypalPurchaseUnits = cartItems.map((items, i) => ({


          reference_id: i,
          description: `Some description sdfs sdf sdf sd fsd f`,
          custom_id: "some_value_will_show_up_in_the_pay_pal_interface",
          soft_descriptor: "product description",
          amount: {
              currency_code: "EUR",
              value: items.price[i].replace(',', '.'),
              breakdown: {
                  item_total: {
                      currency_code: "EUR",
                      value: items.price[i].replace(',', '.')
                  }
              }
          },
          items: [
              {
                  name: items.name[i],
                  description: items.name[i],
                  // sku: {this.state.item.skew},
                  unit_amount: {
                      currency_code: "EUR",
                      value: items.price[i].replace(',', '.')
                  },
                  quantity: 1
              },
          ]
      })
    )

  console.log(paypalPurchaseUnits)

  const time = props.values.time
  const timeCast = new Date(time).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
  if (Array.isArray(cartItems) && cartItems.length) {
    const products = cartItems.map(items => `${ items.name } ${items.price} €`).join("\n")
    const paypalProductList = cartItems.map(items => `${ items.name }, `).join("\n")

    return (
      <>
        <StepperWrapper>
          <StepperContent>
            <h2 className='title is-5'>
              Zusammenfassung
            </h2>
            <ProgressBar percent={254} status={80} />
            <form
              name="reservation-form"
              method="post"
              onSubmit={handleSubmit}
            >

              <div className="field" style={{ marginTop: '1.5rem' }}>
                <p style={{ color: '#000' }}><b>Datum:</b>{' '}{props.values.date}</p>
                <p style={{ color: '#000' }}><b>Uhrzeit:</b>{' '}{timeCast}</p>
                <p style={{ color: '#000' }}><b>Name:</b>{' '}{`${formValues.formValues.secondname}`}</p>
                <p style={{ color: '#000' }}><b>Email:</b>{' '}{formValues.formValues.email}</p>
                <p style={{ color: '#000' }}><b>Tel:</b>{' '}{formValues.formValues.tel}</p>
              </div>

              <div className="field">
                <label className="label" htmlFor={'products'}>
                  Ihre Bestellung
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name={'products'}
                    // onChange={handleChange}
                    id={'products'}
                    value={products}
                    style={{ background: '#000000', color: '#fff' }}
                    readOnly
                  />
                </div>
              </div>

              <RadioWrapper>
                <div className="field" style={{ marginTop: '1.5rem' }}>
                  <label className="label" htmlFor={'area'}>
                    Wähle eine Zahlungsart
                  </label>
                  <div className="control">
                    <label class="container">
                      Sofort
                      <input

                        type={'radio'}
                        value={"online"}
                        name={'payment'}
                        onChange={handleChange('payment')}
                        // onChange={handleChange}
                        id={'payment'}
                        checked={formValues.formValues.payment === 'online' ? formValues.formValues.payment === 'online' : null}
                      />
                      <span class="checkmark"></span>
                    </label>



                  </div>
                  <div className="control">
                    <label class="container">
                      Vor Ort
                      <input
                        type={'radio'}
                        value={"inplace"}
                        name={'payment'}
                        onChange={handleChange('payment')}
                        // onChange={handleChange}
                        id={'payment'}
                        checked={formValues.formValues.payment === 'inplace' ? formValues.formValues.payment === 'inplace' : null }
                      />
                      <span class="checkmark"></span>
                    </label>


                  </div>
                </div>
              </RadioWrapper>

              {values.formValues.payment === 'online' && privacyChecked === true && termsChecked === true && !isPayed ?
                <PayPalButton
                  // amount={sum}
                  // amount={0.01}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                            // description: 'hello',
                            description: paypalProductList,
                            amount: {
                                // currency_code: "EUR",
                                // value: '0.01',
                                value: paypalAmount
                            },
                        },
                      ],


                    // purchase_units: [
                    //   // paypalPurchaseUnits
                    //
                    // // {
                    // //   reference_id: "1",
                    // //   description: "Some description",
                    // //
                    // //   custom_id: "Something7364",
                    // //   soft_descriptor: "Great description 1",
                    // //   amount: {
                    // //       currency_code: "EUR",
                    // //       value: "200.00",
                    // //       breakdown: {
                    // //           item_total: {
                    // //               currency_code: "EUR",
                    // //               value: "200.00"
                    // //           }
                    // //       }
                    // //   },
                    // //   items: [
                    // //     paypalPurchaseUnits
                    // //
                    // //   ]
                    // // }
                    //
                    //   // cartItems.map((items, i) => ({
                    // //   {
                    // //     reference_id: "some_id",
                    // //     description: `Some description ${products}`,
                    // //     custom_id: "some_value_will_show_up_in_the_pay_pal_interface",
                    // //     soft_descriptor: "product description",
                    // //     amount: {
                    // //         currency_code: "EUR",
                    // //         value: cartItems[0].price.replace(',', '.'),
                    // //         breakdown: {
                    // //             item_total: {
                    // //                 currency_code: "EUR",
                    // //                 value: cartItems[0].price.replace(',', '.')
                    // //             }
                    // //         }
                    // //     },
                    // //     items: [
                    // //         {
                    // //             name: cartItems[0].name,
                    // //             description: `#1: ${cartItems[0].name}`,
                    // //             // sku: {this.state.item.skew},
                    // //             unit_amount: {
                    // //                 currency_code: "EUR",
                    // //                 value: cartItems[0].price.replace(',', '.')
                    // //             },
                    // //             quantity: 1
                    // //         },
                    // //     ]
                    // // }
                    //
                    // // {
                    // //     reference_id: "some_idlo",
                    // //     description: `Some description ${products}`,
                    // //     custom_id: "some_value_will_show_up_in_the_pay_pal_interface",
                    // //     soft_descriptor: "product description",
                    // //     amount: {
                    // //         currency_code: "EUR",
                    // //         value: cartItems[1].price.replace(',', '.'),
                    // //         breakdown: {
                    // //             item_total: {
                    // //                 currency_code: "EUR",
                    // //                 value: cartItems[1].price.replace(',', '.')
                    // //             }
                    // //         }
                    // //     },
                    // //     items: [
                    // //       {
                    // //           name: cartItems[1].name,
                    // //           description: `#1: ${cartItems[1].name}`,
                    // //           // sku: {this.state.item.skew},
                    // //           unit_amount: {
                    // //               currency_code: "EUR",
                    // //               value: cartItems[1].price.replace(',', '.')
                    // //           },
                    // //           quantity: 1
                    // //       },
                    // //     ]
                    // // }
                    //
                    // cartItems.map((item, i) => (
                    // {
                    //     reference_id: i,
                    //     description: "Some description",
                    //     custom_id: "some_value_will_show_up_in_the_pay_pal_interface",
                    //     soft_descriptor: "product description",
                    //     amount: {
                    //         currency_code: "EUR",
                    //         value: item[i].price.replace(',', '.'),
                    //         breakdown: {
                    //             item_total: {
                    //                 currency_code: "EUR",
                    //                 value: item[i].price.replace(',', '.')
                    //             }
                    //         }
                    //     },
                    //     items: [
                    //       {
                    //           name: item[i].name,
                    //           description: `#1: ${item[i].name}`,
                    //           // sku: {this.state.item.skew},
                    //           unit_amount: {
                    //               currency_code: "EUR",
                    //               value: item[i].price.replace(',', '.')
                    //           },
                    //           quantity: 1
                    //       },
                    //     ]
                    // })
                    // ]
                    })
                  }}
                  // amount={paypalAmount}
                  // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                  onSuccess={(details, data) => {
                    // console.log(details, data)
                    // alert("Transaction completed by " + details.payer.name.given_name);
                    // alert("Transaktion erfolgreich. Rufe deine Emails ab um zu deiner Rechnung zu gelangen!")

                    const date = props.values.date
                    const time = timeCast
                    const firstname = formValues.formValues.firstname
                    const secondname = formValues.formValues.secondname
                    const email = formValues.formValues.email
                    const tel = formValues.formValues.tel
                    const sum = cartTotal
                    const fullname = firstname + ' ' + secondname + '!'
                    const to = email
                    const products = cartItems.map(items => `${ items.name } ${items.price} €`)

                    const orderInfo = {
                      orderno: data.orderID,
                      firstname: firstname,
                      secondname: secondname,
                      email: email,
                      tel: tel,
                      products: products,
                      checked: false,
                      sum: sum,
                      payment: 'online',
                      // date: time.toLocaleDateString(),
                      // time: time.toLocaleTimeString()
                      date: date,
                      time: time
                    }

                    // Make API request to create new order
                    api.createOrder(orderInfo).then((response) => {
                      console.log(response)

                      // if (response.status === 200) {
                      setSending(false)
                      setSuccess(true)

                      setIsPayed(true)

                      // localStorage.removeItem("click&collect-cart")
                      // setCartItems([])

                      // setTakeAway(false)
                      // setShowThankYouTakeAway(true)
                      // }
                    }).catch((e) => {
                      console.log('An API error occurred', e)

                      setError(true)
                    })

                    const currentDate = new Date()
                    const day = currentDate.getDay()
                    const orderlist = products
                    const takeAwayWaitingTime = waitingTime[day === 0 ? 6 : day - 1]
                    const pickuptime = new Date(currentDate.setTime(currentDate.getTime() + (takeAwayWaitingTime * 60000))).toLocaleTimeString('de', { hour: 'numeric', minute: 'numeric' })
                    const orderno = data.orderID

                    axios.post("/.netlify/functions/send-order-confirmation", {
                      fullname,
                      sum,
                      orderlist,
                      pickuptime,
                      orderno,
                      time,
                      date,
                      tel,
                      to
                    }).then((response) => {
                      // if (response.data.status === 'success'){
                      //   alert("Message Sent.");
                      //   this.resetForm()
                      // }else if(response.data.status === 'fail'){
                      //   alert("Message failed to send.")
                      // }
                      // this.resetForm()
                      // console.log('success', response)
                      if (response.status === 200) {
                        setSending(false)
                        setSuccess(true)

                        handleOrderIdentification(orderno)


                        setTimeout(function () {
                          props.nextStep()
                        }, 1000)
                      }
                      return {
                        statusCode: 200,
                        body: JSON.stringify(response)

                      }
                    }).catch((error) => {
                      // console.log('error', error)
                      setError(true)
                      return {
                        statusCode: 400,
                        body: JSON.stringify(error)
                      }
                    //  console.log('...dont know')
                    })


                    // OPTIONAL: Call your server to save the transaction
                    // return fetch("/paypal-transaction-complete", {
                    //     method: "post",
                    //     body: JSON.stringify({
                    //         orderId: data.orderID
                    //     })
                    // });
                  }}
                  // options={{
                  //     clientId: "PRODUCTION_CLIENT_ID"
                  //
                  // }}
                  // onError={console.log('failure')}
                  options={{
                    clientId: process.env.PAYPAL_PROD,
                    currency: "EUR"
                  }}
                />
                : null}

              {isPayed ?
                <div style={{ margin: '2rem 0rem', textAlign: 'left' }}>
                  <FontAwesomeIcon icon={faCheck} style={{ display: 'inline-block', color: '#00ff00' }} />{' '}<span>Zahlung erfolgreich. Sie werden gleich weitergeleitet</span>
                </div>
                : null}

                {/*<RadioWrapper>
                  <div className="field" style={{ marginTop: '1.5rem' }}>

                    <div className="control">
                      <label class="container" style={{ textAlign: 'right' }}>
                        Ich stimme der Verarbeitung meiner angegebenen Daten für den Zweck und der Dauer der Bestellabwicklung zu.
                        <input
                          className="checkbox"
                          type={'radio'}
                          value={"yes"}
                          name={'privacy'}
                          onChange={handleChange('privacy')}
                          id={'privacy'}
                          required={true}
                          checked={formValues.formValues.privacy === 'yes' ? formValues.formValues.privacy === 'yes' : null}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </RadioWrapper>*/}

                <CheckboxWrapper>
                <label class="container">
                  Ich stimme der Verarbeitung meiner angegebenen Daten für den Zweck und der Dauer der Bestellabwicklung zu.
                  <input
                    type="checkbox"
                    value={privacyChecked}
                    name={'privacy'}
                    onChange={() => setPrivacyChecked(!privacyChecked)}
                    id={'privacy'}
                    required={true}
                  />
                  <span class="checkmark"></span>
                </label>

                  <label class="container">
                  Ich akzeptiere die Allgemeinen Nutzungsbedingungen
                  <input
                    type="checkbox"
                    value={termsChecked}
                    name={'terms'}
                    onChange={() => setTermsChecked(!termsChecked)}
                    id={'terms'}
                    required={true}
                  />
                  <span class="checkmark"></span>
                  </label>
                </CheckboxWrapper>

              <ButtonWrapper>
                <button className="button is-black" onClick={backStep}>
                  zurück&nbsp;<FontAwesomeIcon icon={faAngleLeft} />
                </button>

                {values.formValues.payment === 'inplace' && privacyChecked === true && termsChecked === true ?
                  <button className="button is-primary" type="submit" aria-label="submit" style={{ marginLeft: '.5rem' }}>
                    Absenden&nbsp;
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button> : null}
              </ButtonWrapper>

              <div className='has-text-centered mt-2'>
                {renderSendingTime()}
                {renderSuccess()}
                {renderError()}
              </div>
            </form>
          </StepperContent>
        </StepperWrapper>
        <div className='field'>
          <div style={{ margin: '0 auto', textAlign: 'center'}}>
            <FontAwesomeIcon className='has-text-centered has-text-primary' style={{ margin: '2.5rem auto 0rem' }} icon={faInfoCircle} size='2x' />
          </div>
          <p style={{ color: '#333333', margin: '.5rem auto', maxWidth: '300px' }}>Nach erfolgreicher Bestellung erhalten Sie umgehend eine Email mit der Abholzeit und Infos.</p>
        </div>
      </>
    )
  }
}

export default Confirm

const StepperWrapper = styled.div`
  height: auto;
  position: relative;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: auto;
  max-width: 300px;
  .label{
    color: black !important;
  }
  .field{
    margin: 0 auto;
  }
`

const StepperContent = styled.div`
  height: auto;
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: 10px;
  background: rgba(255,255,255,0.9);
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: auto;
  max-width: 300px;
  .label{
    color: black !important;
  }
  .field{
    margin: 0 auto;
  }
  p{
    text-align: left;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 90px;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Success = styled.div`
  margin: 0 auto;
  width: 250px;
  text-align: center;
  background: hsl(141, 71%, 48%);
  color: #fff;
  padding: 10px;
`

const Info = styled.div`
  margin: 0 auto;
  width: 250px;
  text-align: center;
  background: hsl(212, 57%, 50%);
  color: #fff;
  padding: 10px;
`

const Error = styled.div`
  margin: 1rem auto;
  width: 250px;
  text-align: center;
  background: hsl(348, 100%, 61%);
  color: #fff;
  padding: 10px;
`

const RadioWrapper = styled.div`
  margin: 2rem auto;
  .container {
  display: block;
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  }

  /* Hide the browser's default radio button */
  .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
  position: absolute;
  top: 0;
  left: 20px;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
  background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
  background-color: #DF8136;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
  display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  }
}
`

const CheckboxWrapper = styled.div`
  /* Customize the label (the container) */
  .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
  background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
  background-color: #66bb6a;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
  display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
  left: 9px;
  top: 6px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  }
`
