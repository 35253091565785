import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import InfiniteCalendar from 'react-infinite-calendar'
import 'react-infinite-calendar/styles.css' // only needs to be imported once
import useOutsideClick from '../../hooks/useOutsideClick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
// import logo from '../../img/logo.png'
import { StaticImage } from 'gatsby-plugin-image'

// Render the Calendar
var today = new Date()
// var date = new Date()
var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)


const StepOne = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "bookingtime"}}}) {
          edges {
            node {
              frontmatter {
                disabledDays
              }
            }
          }
        }
      }
    `
  )
  // const { values, reservationDate, showCalendar, showDay, handleChange } = props
  const { values, date, handleChange, handleDateChange, showCalendar } = props
  const [reservationStateDate, setReservationStateDate] = useState()
  // const [showDay, setShowDay] = useState(false)
  // const [disabledDates, setDisabledDates] = useState(['2019-12-28', '2020-11-22', '2021-02-09'])
  const [disabledDates, setDisabledDates] = useState(data.allMarkdownRemark.edges[0].node.frontmatter.disabledDays.map(item => item))
  const [disabledDays, setDisabledDays] = useState([])
  // const [showCalendar, setShowCalendar] = useState(false)
  const ref = useRef()

  // console.log(values.date)

  // Convert from to toLocaleDateString to utc date
  function parseDMY (s) {
    var b = s.split(/\D+/)
    var d = new Date(b[2], b[1]-1, b[0])
    d.setFullYear(b[2])
    return d && d.getMonth() == b[1]-1 ? d : new Date(NaN)
  }

  useEffect(() => {
    if (values.date !== undefined) {
      const selectedStateDateConvert = parseDMY(values.date.toString())
      setReservationStateDate(selectedStateDateConvert)
    }
  }, [props])

  function handleCalendarOpening (date) {
    handleDateChange(date)
    props.handleCalendarShowing()
    props.nextStep()
  }

  useOutsideClick(ref, () => {
    if (props.showCalendar === true) {
      props.handleCalendarShowing()
    }
  })

  function continueStep (e) {
    e.preventDefault()
    props.nextStep()
  }

  return (
    <>
      <div style={{ margin: '0 auto', display: 'inline-block' }}>
        {showCalendar ?
          <>
            <ModalWrapper>
              {/*<ModalContent ref={ref}>*/}
              <ModalContent>
                <Close onClick={props.handleCalendarShowing}>
                  <FontAwesomeIcon icon={faTimes} style={{ fontSize: '1.5rem' }} />
                </Close>
                <InfiniteCalendar
                  width={310}
                  height={200}
                  className={'center'}
                  rowHeight={60}
                  selected={reservationStateDate ? reservationStateDate : today}
                  disabledDays={disabledDays}
                  disabledDates={disabledDates}
                  minDate={today}
                  locale={{
                    locale: require('date-fns/locale/de'),
                    headerFormat: 'dddd, D MMM',
                    weekdays: ["So","Mo","Di","Mi","Do","Fr","Sa"],
                    blank: 'Datum auswählen',
                    todayLabel: {
                      long: 'Heute'
                    }
                  }}
                  theme={{
                    selectionColor: '#DF8136',
                    textColor: {
                      default: '#333',
                      active: '#FFF'
                    },
                    weekdayColor: '#000000',
                    headerColor: '#DF8136',
                    floatingNav: {
                      background: '#DF8136',
                      color: '#FFF',
                      chevron: 'black'
                    }
                  }}

                  onSelect={date => handleCalendarOpening(date.toLocaleDateString())}
                  // onChange={handleChange(date)}

                />
              </ModalContent>
            </ModalWrapper>
          </> : null}
      </div>

      <ModalContent>
        <h2 className='title is-5' style={{ color: '#333333', padding: '1rem', textAlign: 'center', marginBottom: '0px' }}>
          <p className='has-text-primary'>Abholung</p> für{' '}{props.values.date}{' '}
        </h2>

        <Fade>
          <DatePickerWrapper onClick={props.handleCalendarShowing}>
            <FontAwesomeIcon icon={faCalendarAlt} className='has-text-primary has-text-centered' style={{ fontSize: '3rem' }} />

            <p style={{ color: '#000' }}>{values.date !== undefined ? 'anderes Datum wählen' : 'Datum wählen'}</p>
          </DatePickerWrapper>
          <div className="field" style={{ display: 'none' }}>
            <label className="label" htmlFor={'date'}>
              Datum
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'date'}
                onChange={handleChange}
                // onClick={openCalendar}
                id={'date'}
                required={true}
                value={date}
                readOnly
              />
            </div>
          </div>

        </Fade>

        <LogoWrapper>
          {values.date === undefined ?
            <div style={{ margin: '2rem auto', position: 'absolute', bottom: '-55px', right: '-20px', display: 'block', width: '160px' }}>
              <StaticImage
                src='../../../img/click-collect.png'
                alt='logo'
                placeholder='blurred'
                layout='fixed'
                width={160}
                style={{ transform: 'rotate(325deg)', opacity: '0.3' }}
              />
            </div> : null}
          {values.date !== undefined ? <button className='button is-primary' onClick={(e) => continueStep(e)} style={{ marginTop: '3rem' }}>weiter&nbsp;<FontAwesomeIcon icon={faAngleRight} /></button> : null}
        </LogoWrapper>
      </ModalContent>
    </>
  )
}

export default StepOne

const ModalWrapper = styled.div`
  margin: 0 auto;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  // z-index: 20000;
  font-size: 13px;
  width: 90%;
  margin: 0 auto;
  overflow: scroll;
  height: 100%;
  z-index: 3002;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContent = styled.div`
  height: 50vh;
  position: relative;
  margin: 0 auto;
  // background: rgba(255,255,255,0.9);
  // background: transparent;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: auto;
  max-width: 300px;
  .label{
    color: black !important;
  }
  .field{
    margin: 0 auto;
  }
`

const Close = styled.div`
  position: absolute;
  color: #fff;
  z-index: 22000;
  right: 5px;
  top: 15px;
  font-size: 16px;
  cursor: pointer;
`

const DatePickerWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 3001;
  &:hover{
    cursor: pointer;
  }
  margin-top: 3rem;
`

const LogoWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 180px;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CheckWrapper = styled.div`
  position: relative;
  z-index: 3000;
  // margin: .5rem auto;
  // width: 250px;
  text-align: center;
  // background: ${props => props.type};
  color: #000000;
  // padding: 1.6rem;
  font-size: 32px;
  // box-shadow: 0 0 20px rgba(0,0,0,.5);
`
