import React from 'react'
import Layout from '../layouts'
import Cart from '../components/click-collect/Cart'
import Fade from 'react-reveal/Fade'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const CartPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "cart-categories" } }}
        ) {
          edges {
            node {
              frontmatter {
                bg {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                    }
                  }
                }
              }
            }
          }
        }
        ...LayoutFragment
      }
    `
  )


  const { markdownRemark: post, footerData, navbarData, bannerData } = data

  return (
    <Layout navbarData={navbarData} bannerData={bannerData}>
    <div className='imageContainer' style={{ position: 'relative' }}>
      <Img
        fluid={data.allMarkdownRemark.edges[0].node.frontmatter.bg.childImageSharp.fluid}
        style={{
          width: '100%',
          height: '100vh',
          position: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'brightness(40%)'
        }}
      />
    </div>
    {/*<Layout navbarData={navbarData} footerData={footerData} bannerData={bannerData}>*/}
      <Fade>
        <Cart />
      </Fade>
    </Layout>
  )
}

export default CartPage

// export const cartPageQuery = graphql`
//   query CartPage {
//     ...LayoutFragment
//   }
// `
