/* Api methods to call /functions */

const createOrder = (data) => {
  return fetch('/.netlify/functions/order-create', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const readOrderTimes = () => {
  return fetch('/.netlify/functions/read-ordertimes').then((response) => {
    return response.json()
  })
}


export default {
  createOrder: createOrder,
  readOrderTimes: readOrderTimes
}
