import React, { useContext } from 'react'
import styled from 'styled-components'
import logo from '../../../img/click-collect.png'
import { ProgressBar } from '../ProgressBar'
import { CartItemsContext } from '../../../context/CartItemsContext'

const Success = (props) => {
  const [cartItems, setCartItems] = useContext(CartItemsContext)
  const { values } = props

  function backToStart (e) {
    e.preventDefault()
    localStorage.removeItem("click&collect-cart")
    setCartItems([])
    props.firstStep()
  }

  return (
    <>
      <StepperWrapper>
        <StepperContent>
          <h2 className='title is-5 is-spaced'>Danke für Ihre Bestellung!</h2>
          <ProgressBar percent={280} status={100} />
          <p style={{ margin: '2rem auto' }}>Bitte prüfen Sie Ihren Email-Eingang (auch SPAM) für die Bestätigung der Bestellung</p>
          Ihre Bestellnr. lautet: <b>{values.orderId}</b>


          <div>
            <img src={logo} style={{ width: '70px', display: 'block', margin: '2rem auto' }} alt='logo' />
          </div>
          <div className='column is-12 has-text-centered'>
            <button className='button is-primary' onClick={backToStart}>schließen X</button>
          </div>
        </StepperContent>
      </StepperWrapper>
    </>
  )
}

export default Success

const StepperWrapper = styled.div`
  height: auto;
  position: relative;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: auto;
  max-width: 300px;
  .label{
    color: black !important;
  }
  .field{
    margin: 0 auto;
  }
`

const StepperContent = styled.div`
  height: auto;
  position: relative;
  margin: 0 auto;
  padding: 10px;
  background: rgba(255,255,255,0.9);
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: auto;
  max-width: 300px;
  .label{
    color: black !important;
    text-align: left;
  }
  .field{
    margin: 0 auto;
  }
  p{
    text-align: left;
  }
`
