import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
// import { navigate } from 'gatsby-link'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import '../../timePicker.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { ProgressBar } from '../ProgressBar'
// import disabledH from './FetchBookingHours'

// const useFocus = () => {
//   const htmlElRef = useRef(null)
//   const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
//   return [htmlElRef, setFocus]
// }

const StepTwo = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "bookingtime"}}}) {
          edges {
            node {
              frontmatter {
                disabledHours
              }
            }
          }
        }
      }
    `
  )

  const { values, handleTimeChange } = props
  // console.log(`time is ${props.values.time}`)
  // console.log(disabledHours)

  function continueStep (e) {
    e.preventDefault()
    props.nextStep()
  }

  function backStep (e) {
    e.preventDefault()
    props.prevStep()
  }

  function handleDisabledHours () {
    const disabledHours = data.allMarkdownRemark.edges[0].node.frontmatter.disabledHours.map(item => Number(item))
    return disabledHours
  }

  function handleTimeValueChange (time) {
    // setStarttimevalue(starttimevalue)
    handleTimeChange(time)
  };

  return (
    <StepperWrapper>
      <StepperContent>

        <h2 className='title is-5'>
          Uhrzeit
        </h2>
        <ProgressBar percent={values.time !== null ? 74 : 60} status={20} />
        <div className="field" style={{ padding: '1rem auto' }}>
          <div className="control">

            <div className="field" style={{ marginTop: '1.5rem' }}>
              <TimeWrapper>
                <div className="field">
                  <label className="label" htmlFor={'start'}>
                    Uhrzeit wählen
                  </label>
                  <div className="control">
                    <TimePicker
                      defaultValue={props.values.time} showSecond={false}
                      style={{ width: '113px', caretColor: '#DF8136' }}
                      onChange={handleTimeValueChange}
                      // ref={inputStarttimeRef}
                      disabledHours={handleDisabledHours}
                      // disabledHours={<DisabledHours data={data} />}
                      hideDisabledOptions
                      minuteStep={5}
                    />
                  </div>
                </div>
              </TimeWrapper>
            </div>
          </div>
        </div>


        <ButtonWrapper>
          {/*
            {console.log(values.time)}
          */}
          <button className="button is-black" onClick={backStep}>
            zurück&nbsp;<FontAwesomeIcon icon={faAngleLeft} />
          </button>

          {(values.time !== null && values.time !== undefined) ?
            <button
              className="button is-primary"
              onClick={continueStep}
              style={{ marginLeft: '.5rem' }}
            >
            weiter&nbsp;<FontAwesomeIcon icon={faAngleRight} />
            </button> : null}
        </ButtonWrapper>

      </StepperContent>
    </StepperWrapper>
  )
}
export default StepTwo

const StepperWrapper = styled.div`
  height: 50vh;
  position: relative;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: 310px;
  .label{
    color: black !important;
  }
  .field{
    margin: 0 auto;
  }
`

const StepperContent = styled.div`
  text-align: center;
  height: 50vh;
  position: relative;
  margin: 0 auto;
  padding: 10px;
  background: rgba(255,255,255,0.9);
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: auto;
  max-width: 300px;
  .label{
    color: black !important;
  }
  .field{
    margin: 0 auto;
  }
  p{
    text-align: left;
  }
`

const TimeWrapper = styled.div`
  display: inline-block;

  .rc-time-picker-input{
    border-radius: 0px;
    outline: none;
    height: 40px;
    caret-color: #DF8136 !important;
    // box-shadow: 0px 0px 0px 1px rgba(120, 130, 152, 0.25);
    border: 2px solid #d9d9d9;
    -webkit-appearance: none;
    font-size: 14px;
    transition: all 0.3s ease;
    :hover, :active, :focus {
      // box-shadow: 0px 0px 0px 1px rgb(43, 190, 185);
      // box-shadow: 0px 0px 0px 2px black;
    }
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 180px;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
`
