import React, { useState } from 'react'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive from './StepFive'
import Confirm from './Confirm'
import Success from './Success'

const UserForm = (props) => {
  const [step, setStep] = useState(1)
  const [formValues, setFormValues] = useState({ date: '', email: '', firstname: '', lastname: '', tel: '', payment: '', terms: '', privacy: '' })
  // const { firebase, user } = useContext(FirebaseContext)
  const [date, setDate] = useState()
  const [time, setTime] = useState()
  const [showCalendar, setShowCalendar] = useState(false)
  const [formProgress, setFormProgress] = useState('20%')
  const [orderId, setOrderId] = useState()

  // const isFirstRender = useRef(true)

  function nextStep () {
    setStep(step + 1)
  }

  function prevStep () {
    setStep(step - 1)
  }

  function firstStep () {
    setStep(1)
  }

  // function showCalendarDay () {
  //   setShowDay(true)
  //   setShowCalendar(!showCalendar)
  //   // setShowCalendar(false)
  // }

  function handleCalendarShowing () {
    setShowCalendar(!showCalendar)
  }

  function handleFormProgress (percentage) {
    setFormProgress(percentage)
  }



  // useEffect(() => {
  //   if (!isFirstRender.current) {
  //     handleChange()
  //   }
  // }, [formValues])
  //
  // useEffect(() => {
  //   isFirstRender.current = false // toggle flag after first render/mounting
  // }, [])

  const handleChange = input => (e) => {
    e.persist()
    // setErrorMessage('')
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }

  function handleDateChange (date) {
    setDate(date)
  }

  function handleTimeChange (time) {
    setTime(time)
  };

  function handleOrderID (orderId) {
    setOrderId(orderId)
  }

  const values = { formValues, date, time, orderId }

  switch (step) {
    case 1:
      return (
        <StepOne
          nextStep={nextStep}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          values={values}
          showCalendar={showCalendar}
          handleCalendarShowing={handleCalendarShowing}
        />
      )
    case 2:
      return (
        <StepTwo
          nextStep={nextStep}
          prevStep={prevStep}
          handleTimeChange={handleTimeChange}
          values={values}
          handleFormProgress={handleFormProgress}
          formProgress={formProgress}
        />
      )
    case 3:
      return (
        <StepThree
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={values}
        />
      )
    case 4:
      return (
        <StepFour
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={values}
        />
      )
    case 5:
      return (
        <StepFive
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={values}
        />
      )
    case 6:
      return (
        <Confirm
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          handleOrderID={handleOrderID}
          values={values}
        />
      )
    case 7:
      return (
        <Success
          firstStep={firstStep}
          values={values}
        />
      )
  }
}

export default UserForm
