import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { ProgressBar } from '../ProgressBar'

// const useFocus = () => {
//   const htmlElRef = useRef(null)
//   const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
//   return [htmlElRef, setFocus]
// }

const StepFive = (props) => {
  const { values, reservationDate, handleChange } = props
  const [formValues, setFormValues] = useState(props.values)

  // console.log(`is ${values.date}`)

  function continueStep (e) {
    e.preventDefault()
    props.nextStep()
  }

  function backStep (e) {
    e.preventDefault()
    props.prevStep()
  }

  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  return (
    <StepperWrapper>
      <StepperContent>
        <h2 className='title is-5'>
          Email
        </h2>
        <ProgressBar percent={182} status={60} />
        <div className="field" style={{ padding: '1rem auto' }}>
          <div className="control">
            <div className="field" style={{ marginTop: '1.5rem' }}>
              <label className="label" htmlFor={'email'}>
                Email Adresse
              </label>

              <div className="control">
                <InputField
                  type='email'
                  name='email'
                  id={'email'}
                  onChange={handleChange('email')}
                  value={formValues.email}
                  defaultValue={formValues.formValues.email}
                  style={{ width: '280px' }}
                  // ref={inputNameRef}
                />
              </div>
            </div>
          </div>
        </div>

        <ButtonWrapper>
          <button className="button is-black" onClick={backStep}>
            zurück&nbsp;<FontAwesomeIcon icon={faAngleLeft} />
          </button>

          {(values.formValues.email !== '' && values.formValues.email !== undefined && values.formValues.email.match(mailformat)) ?
          <button className="button is-primary" onClick={continueStep} style={{ marginLeft: '.5rem' }}>
            weiter&nbsp;<FontAwesomeIcon icon={faAngleRight} />
          </button> : null}
        </ButtonWrapper>

      </StepperContent>
    </StepperWrapper>
  )
}

export default StepFive

const InputField = styled.input`
  font-size: 14px;
  padding: 11px 15px;
  width: 240px;
  display: inline-block;
  box-shadow: 0px 0px 0px 1px rgba(120, 130, 152, 0.25);
  border: 1px solid #d9d9d9;
  outline: none;
  caret-color: #DF8136;
  -webkit-appearance: none;
  border-radius: 0px;
  transition: all 0.3s ease;
  :hover, :active, :focus {
    box-shadow: 0px 0px 0px 2px rgb(43, 190, 185);
    box-shadow: 0px 0px 0px 2px #DF8136;
  }
`

const StepperWrapper = styled.div`
  height: 50vh;
  position: relative;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: auto;
  max-width: 300px;
  .label{
    color: black !important;
    text-align: left;
  }
  .field{
    margin: 0 auto;
  }
`

const StepperContent = styled.div`
  height: 50vh;
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: 10px;
  background: rgba(255,255,255,0.9);
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 6px 0 rgba(14,30,37,0.12);
  width: auto;
  max-width: 300px;
  .label{
    color: black !important;
  }
  .field{
    margin: 0 auto;
  }
  p{
    text-align: left;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: 180px;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
`
